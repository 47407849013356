<template>
    <Modal :class="MODAL_NAME" :name="MODAL_NAME" :options="{ overlay: true }" fixed isHighest width="332px" @close="closeModal">
        <template>
            <div class="title">{{ $t('ui.betslip.shareYourBet') }}</div>
            <SocialButtons :share-links="getShareLinks()" @socialLinkClicked="onSocialLinkClick" />
            <div v-if="isCopiedLinkMessageVisible" class="notify success copied-link-message">
                {{ $t('ui.common.copied') }}
            </div>
        </template>
    </Modal>
</template>

<script>
import SocialButtons from '@/modules/sport/components/Fragments/Betslip/SocialButtons.vue';
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@/modules/platform';
import { NOTIFICATION_DURATION } from '@/const/notification';
import { socialShareType } from '@agi.packages/sport';
import { directShareLinkTemplateFn } from '@/modules/sport/utils/betslip/directShareLinkTemplateFn';
import { getSocialLinks } from '@/modules/sport/utils/betslip/getSocialLinks';
import { getBetSharingLinks } from '@/modules/sport/utils/betslip/getBetSharingLinks';
import { getObjectField } from '@/modules/core/utils/helper';

const MODAL_NAME = 'big-win-sharing-modal';

export default {
    name: 'BigWinSharingModal',
    components: { SocialButtons },
    data() {
        return {
            isCopiedLinkMessageVisible: false,
            MODAL_NAME,
        };
    },
    computed: {
        ...mapGetters({
            bigWin: platformGetter.GET_BIG_WIN,
            user: platformGetter.GET_USER_SETTINGS,
        }),
    },
    methods: {
        closeModal() {
            this.$modal.hide(this.MODAL_NAME);
        },
        onSocialLinkClick(linkTypeId) {
            if (socialShareType.DIRECT === linkTypeId) {
                this.setCopiedLinkMessageVisible();
            }

            this.trackSharingAction();
        },
        setCopiedLinkMessageVisible() {
            this.isCopiedLinkMessageVisible = true;

            setTimeout(() => {
                this.isCopiedLinkMessageVisible = false;
            }, NOTIFICATION_DURATION);
        },
        trackSharingAction() {
            const gtmQuery = getObjectField(this.$modal.params, `${this.MODAL_NAME}.gtmEvent`);
            if (gtmQuery) {
                this.$gtm.query({
                    ...gtmQuery,
                    userId: this.user.userUuid,
                });
            }
        },
        getShareLinks() {
            const directShareLink = directShareLinkTemplateFn('bigWin', true);
            return getBetSharingLinks(getSocialLinks(directShareLink, this.$t('ui.bigWin.shareText', { url: window.location.origin })));
        },
    },
};
</script>

<style scoped lang="scss">
.big-win-sharing-modal.modal-mask.overlay {
    padding: 16px;

    ::v-deep .modal-wrapper {
        align-items: center;

        .modal-container {
            padding-bottom: 0;

            .modal-header {
                height: 20px;

                .modal-header-button.no-title {
                    margin: 12px 12px 0 0;
                }
            }

            .modal-body {
                text-align: center;
                padding-top: 0;
            }
        }
    }
    .title {
        @extend %h4-font-700;
        text-align: center;
        color: $grey-text;
        margin-bottom: 8px;
    }
    .notify.copied-link-message {
        margin: 10px 0 24px;
        height: 32px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
}
</style>
