<template>
    <router-link class="league-shortcut-box-wrapper" :to="link" @click.native="trackShortCutClick">
        <div class="league-shortcut-box">
            <div class="league-shortcut-box-top">
                <slot name="icon"></slot>
                <div v-if="eventCount > 0" class="league-shortcut-box-count">{{ eventCount }}</div>
            </div>
            <div class="league-shortcut-box-name">{{ name }}</div>
        </div>
    </router-link>
</template>
<script>
export default {
    name: 'LeagueShortcutBox',
    props: {
        name: {
            type: String,
            required: true,
        },
        eventCount: {
            type: Number,
            required: true,
        },
        link: {
            type: [String, Object],
            default: () => ({}),
        },
    },
    methods: {
        trackShortCutClick() {
            this.$gtm.query({ event: 'league_shortcut_clicked' });
        },
    },
};
</script>

<style lang="scss" scoped>
.league-shortcut-box-wrapper {
    margin-right: 8px;

    &:last-of-type {
        margin-right: 0;
    }

    .league-shortcut-box {
        width: 88px;
        border: 1px solid $medium-grey;
        border-radius: 6px;
        padding: 8px 8px 4px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;

        .league-shortcut-box-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .league-shortcut-box-icon {
                width: 11px;
                height: 11px;
            }

            .league-shortcut-box-count {
                min-width: 16px;
                padding: 0 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $message-success;
                @extend %caption-font-400;
                font-size: 8px;
                border-radius: 4px;
                color: $main-text;
            }
        }

        .league-shortcut-box-name {
            padding-top: 4px;
            @extend %caption-font-400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
        }
    }
}
</style>
